<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.name"
        label="搜索活动"
        v-bind="layout"
        placeholder="请输入活动关键词"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="id"
        label="所属分组"
        inner-search
        placeholder="全部分组"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjSelect
        v-model="search.activity_status"
        v-bind="layout"
        key-field="id"
        label="活动状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="发布状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="statusData"
      />
    </BjSearch>
    <div :class="$style.table">
      <p :class="$style.title">
        活动列表
        <dustbin :class="$style.dustbin" :count="recycleTotal" @refresh="refresh" />
      </p>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #cover="item">
          <div :class="$style.cover">
            <img :class="$style.img" :src="item.cover.cover" />
            <div class="ellipsis3">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template #status="item">
          <BjTag :type="item.status ? 'primary' : 'default'">{{ item.status === 1 ? '已发布' : '未发布' }}</BjTag>
        </template>
        <template #num="item">
          <div>访问人数： {{ item.read_num }}</div>
          <div>报名人数： {{ item.sign_num }}</div>
        </template>
        <template #time="item">
          <div>{{ item.start_time }}</div>
          <div>{{ item.end_time }}</div>
        </template>
        <template #action="item">
          <bj-link type="primary" :disabled="!item.is_sign" @click="onReport(item.id)"> 登记 </bj-link>
          <bj-link type="primary" @click="onEdit(item)"> 编辑 </bj-link>
          <bj-link type="primary" @click="onPreview(item)"> 预览 </bj-link>
          <a-popconfirm title="确定要删除吗？" @confirm="onDelete(item)">
            <bj-link type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { appActivityManage } from '@/service'

import dustbin from './dustbin.vue'

const service = new appActivityManage()

export default {
  name: 'Home',
  components: {
    dustbin,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      data: [],
      search: {
        keyword: null,
        group_id: '',
        status: 0,
        activity_status: 0,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      statusData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '已发布',
          id: 1,
        },
        {
          name: '未发布',
          id: 2,
        },
      ],
      typeData: [
        {
          name: '全部状态',
          id: 0,
        },
        {
          name: '未开始',
          id: 1,
        },
        {
          name: '进行中',
          id: 2,
        },
        {
          name: '已结束',
          id: 3,
        },
      ],
      groupData: [],
      recycleTotal: 0,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '活动名称',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '所属分组', dataIndex: 'group_name' },
        {
          title: '活动人数',
          scopedSlots: {
            customRender: 'num',
          },
        },
        {
          title: '活动时间',
          scopedSlots: {
            customRender: 'time',
          },
        },
        {
          title: '活动状态',
          dataIndex: 'activity_status',
          customRender: value => {
            switch (value) {
              case 1:
                return <bj-tag type='default'>未开始</bj-tag>
              case 2:
                return <bj-tag type='primary'>进行中</bj-tag>
              case 3:
                return <bj-tag type='default'>已结束</bj-tag>
            }
          },
        },
        {
          title: '发布状态',
          dataIndex: 'status',
          customRender: value => {
            switch (value) {
              case 1:
                return <bj-tag type='primary'>已发布</bj-tag>
              case 2:
                return <bj-tag type='default'>未发布</bj-tag>
            }
          },
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '管理',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getGroup()
    this.getList()
    this.getTotal()
  },
  methods: {
    async getTotal() {
      const { data } = await service.getTotal()
      this.recycleTotal = data
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        keyword: null,
        group_id: '',
        status: 0,
        activity_status: 0,
      }
      this.onSearch()
    },
    async getList() {
      const { data } = await service.getList({
        page: this.page,
        page_size: this.pageSize,
        ...this.search,
      })
      this.data = data.record
      this.total = data.total
    },
    async getGroup() {
      const { data } = await service.getGroup()
      this.groupData = [{ name: '全部分类', id: '' }].concat(data)
    },
    onEdit(item) {
      this.$router.push({
        name: 'appActivityAdd',
        query: {
          id: item.id,
        },
      })
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'app_activity',
          id: item.id,
        },
      })
    },
    refresh() {
      this.getList()
      this.getTotal()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onDelete(item) {
      await service.delete({ id: item.id })
      this.refresh()
      this.$message.success('删除成功')
    },
    onReport(_id) {
      this.$router.push({
        name: 'appActivityReport',
        query: {
          id: _id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    min-height: calc(100vh - 252px);
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .title {
      position: relative;
      color: #000;
      font-size: 16px;
    }

    .dustbin {
      position: absolute;
      right: 40px;
    }

    .cover {
      display: flex;
      align-items: center;
      max-width: 250px;
      color: #000;
      font-size: 14px;

      .img {
        width: 64px;
        height: 64px;
        margin-right: 10px;
        border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
