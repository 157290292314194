<template>
  <div :class="$style.dustbin" @click="onClick()">
    <a-badge
      :count="count"
      :number-style="{
        backgroundColor: '#1470df',
        color: '#fff',
      }"
      :offset="[22, 12]"
    >
      <i class="ri-delete-bin-4-line" />
      回收站
    </a-badge>
    <bj-modal
      title="回收站"
      :visible="visible"
      :width="1000"
      :body-style="{ height: '520px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <div class="mb-20">
          <BjButton
            v-permission="'app.news.manage-recycle.delete'"
            :loading="btnLoading"
            type="primary"
            @click="onClear()"
            >全部清空</BjButton
          >
          <span class="ml-20 tip">删除的内容会在回收站保留 14 天，之后将被系统彻底删除。</span>
        </div>
        <a-spin v-if="loading" :class="$style.spin" />
        <div v-else>
          <a-table :columns="columns" :data-source="data" :pagination="false" row-key="id">
            <template #cover="item">
              <div :class="$style.cover">
                <img :class="$style.img" :src="item.cover.cover" />
                <div class="ellipsis3">
                  {{ item.name }}
                </div>
              </div>
            </template>
            <template #status="item">
              <BjTag :type="item.status ? 'primary' : 'default'">{{ item.status === 1 ? '已发布' : '未发布' }}</BjTag>
            </template>
          </a-table>
          <div class="pagination">
            <a-pagination
              :current="page"
              show-size-changer
              :show-total="total => `共 ${total} 条`"
              :page-size.sync="pageSize"
              :total="total"
              @showSizeChange="onShowSizeChange"
              @change="onPageChange"
            />
          </div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { appActivityManage } from '@/service'

const service = new appActivityManage()

export default {
  name: 'dustbin',
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      loading: false,
      btnLoading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '活动名称',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '发布状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '管理',
          customRender: item => [
            <bj-link
              v-permission='app.news.manage-recycle.recover'
              type='primary'
              onClick={() => this.onRecovery(item.id)}
            >
              恢复
            </bj-link>,
            <a-popconfirm title='确定要彻底删除吗？删除之后就不能再次找回。' onConfirm={() => this.onDelete(item.id)}>
              <bj-link v-permission='app.news.manage-recycle.delete' type='danger'>
                彻底删除
              </bj-link>
            </a-popconfirm>,
          ],
        },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getList()
      }
    },
  },
  methods: {
    onClick() {
      this.visible = true
    },
    onClear() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        content: '确定要清空全部回收站数据吗？清空之后就不能再次找回。',
        onOk() {
          _this.clear()
        },
      })
    },
    async onRecovery(id) {
      try {
        await service.recycleRecover({
          id: id,
        })
        this.$message.success('恢复成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.getList()
        this.$emit('refresh')
      }
    },
    async onDelete(id) {
      try {
        await service.recycleDelete({
          id: id,
        })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.getList()
        this.$emit('refresh')
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async clear() {
      try {
        this.btnLoading = true
        await service.recycleClear()
        this.$message.success('清空成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.btnLoading = false
        this.getList()
        this.$emit('refresh')
      }
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.recycleList({
          page: this.page,
          page_size: this.pageSize,
        })
        this.total = data.total
        this.data = data.record
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.dustbin {
  display: inline-block;
  color: #000;
  cursor: pointer;

  i {
    position: relative;
    top: 2px;
    font-size: 20px;
  }
}

.modal {
  .box {
    display: flex;
    align-items: center;
    width: 100px;
    color: @primary-color;

    span {
      margin-left: 10px;
    }
  }

  .cover {
    display: flex;
    align-items: center;
    max-width: 250px;
    color: #000;
    font-size: 14px;

    .img {
      width: 64px;
      height: 64px;
      margin-right: 10px;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
